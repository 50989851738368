// firebase/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-UFDrz_6rp5vGUzoEjzw-mDyEbRJ-EBQ",
  authDomain: "higlo-proto-00.firebaseapp.com",
  projectId: "higlo-proto-00",
  storageBucket: "higlo-proto-00.appspot.com",
  messagingSenderId: "628475261763",
  appId: "1:628475261763:web:7f76a8cbce216dac1d35f6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };
