import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';

function SignIn({ onEmailSubmit }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailDisabled, setEmailDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send email to backend
    try {
      const response = await fetch(
        'https://api-4nffdszqla-uc.a.run.app/user/start',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (data.allowed) {
        // Proceed to code verification
        onEmailSubmit(email);
      } else {
        // Show message and disable email input
        setMessage(data.message || 'You are not on the pre-approved list.');
        setEmailDisabled(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  const handleGoBack = () => {
    setEmailDisabled(false);
    setMessage('');
  };

  return (
    <Container className="mt-5" style={{ maxWidth: "400px" }}>
      <h2 className="text-center mb-4">Sign In</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email" className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={emailDisabled}
            required
          />
        </Form.Group>
        {!emailDisabled && (
          <Button type="submit" variant="primary">
            Start
          </Button>
        )}
      </Form>
      {message && (
        <Alert variant="danger" className="mt-3">
          <p>{message}</p>
          <Button variant="secondary" onClick={handleGoBack}>
            Go Back
          </Button>
        </Alert>
      )}
    </Container>
  );
}

export default SignIn;
