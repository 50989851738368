import React, { useState } from 'react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { app } from '../../firebase/firebaseConfig';

function VerifyCode({ email, onVerificationSuccess }) {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send email and code to backend
    try {
      const response = await fetch(
        'https://api-4nffdszqla-uc.a.run.app/user/verify',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, code }),
        }
      );
      console.log('here');
      console.log(response);
      const data = await response.json();
      console.log(data);
      if (data.success) {
        // Sign in with custom token
        const auth = getAuth(app);
        await signInWithCustomToken(auth, data.token);
        onVerificationSuccess();
      } else {
        setMessage(data.error || 'Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: "400px" }}>
      <h2 className="text-center mb-4">Sign In</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email" className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="verificationCode" className="mb-3">
          <Form.Label>Verification Code (has been emailed)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter verification code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </Form.Group>
        <Button type="submit" variant="primary">
          Verify
        </Button>
      </Form>
      {message && (
        <Alert variant="danger" className="mt-3">
          <strong>{message}</strong>
        </Alert>
      )}
    </Container>
  );
}

export default VerifyCode;
