import React, { useEffect, useState } from "react";
import SignIn from './components/Auth/SignIn';
import VerifyCode from './components/Auth/VerifyCode';
import AccountSetup from './components/Auth/AccountSetup';
import IndividualDashboard from './components/IndividualDashboard/IndividualDashboard';
import HomeDashboard from './components/HomeDashboard/HomeDashboard';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebaseConfig";

function App() {
  const [step, setStep] = useState('signin'); // 'signin', 'verify', 'setup', 'dashboard', 'home'
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('');
  const [familyIDs, setFamilyIDs] = useState([]);
  const [familyName, setFamilyName] = useState('');
  const [selectedFamilyID, setSelectedFamilyID] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setEmail(currentUser.email);
        try {
          const idToken = await currentUser.getIdToken();
          // Fetch user info to determine next step
          const response = await fetch(`https://api-4nffdszqla-uc.a.run.app/user/userinfo?email=${currentUser.email}`, {
            headers: {
              'Authorization': `Bearer ${idToken}`
            }
          });
          const data = await response.json();
          console.log(data);
          if (data.needsSetup) {
            setUserType(data.userType);
            setFamilyIDs(data.familyIDs);
            setFamilyName(data.familyName);
            setStep('setup');
          } else {
            setStep('dashboard');
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
          // Handle error, possibly set a state to show an error message
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleEmailSubmit = (email) => {
    setEmail(email);
    setStep('verify');
  };

  const handleVerificationSuccess = () => {
    setStep('setup');
  };

  const handleSetupComplete = () => {
    setStep('dashboard');
  };

  const handleManageFamily = (familyID) => {
    setSelectedFamilyID(familyID);
    setStep('home');
  };

  const handleReturn = () => {
    setStep('dashboard');
  };

  if (step === 'signin') {
    return <SignIn onEmailSubmit={handleEmailSubmit} />;
  } else if (step === 'verify') {
    return <VerifyCode email={email} onVerificationSuccess={handleVerificationSuccess} />;
  } else if (step === 'setup') {
    return (
      <AccountSetup
        email={email}
        userType={userType}
        familyIDs={familyIDs}
        familyName={familyName}
        onSetupComplete={handleSetupComplete}
      />
    );
  } else if (step === 'dashboard') {
    return <IndividualDashboard onManageFamily={handleManageFamily} />;
  } else if (step === 'home') {
    return <HomeDashboard familyID={selectedFamilyID} onReturn={handleReturn} />;
  }
}

export default App;
