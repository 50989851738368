// src/components/Dashboard/Dashboard.js

import FamilyInfo from "./FamilyInfo";
import Controllers from "./Controllers";
import Igloos from "./Igloos";

function HomeDashboard({ familyID, onReturn }) {
  return (
    <div id="main-content">
      <FamilyInfo familyID={familyID} onReturn={onReturn} />
      <Controllers familyID={familyID} onReturn={onReturn} />
      <Igloos familyID={familyID} />
    </div>
  );
}

export default HomeDashboard;
