// src/components/Dashboard/FamilyInfo.js
import React, { useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { db, auth } from "../../firebase/firebaseConfig";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { Button, Container, Card } from "react-bootstrap";


function FamilyInfo({ familyID, onReturn }) {
  const [isEditing, setIsEditing] = useState(false);
  const [familyName, setFamilyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFamilyData = async () => {
      const familyDocRef = doc(db, "familyInfo", familyID);
      try {
        const docSnap = await getDoc(familyDocRef);
        if (docSnap.exists()) {
          setFamilyName(docSnap.data().name);
        }
      } catch (error) {
        console.error("Error fetching family data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFamilyData();
  }, [familyID]);

  const handleEditToggle = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    const familyDocRef = doc(db, "familyInfo", familyID);
    try {
      await updateDoc(familyDocRef, { name: familyName });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating family name:", error);
    }
  };

  const handleCancel = () => {
    //setFamilyName("");
    setIsEditing(false);
  };

  const handleChange = (evt) => {
    setFamilyName(evt.target.value);
  };

  return (
    <Container id="family-section" className="mt-4">
      {/*<h3>Managing Family</h3>*/}
      <Card className="mb-4">
        <Card.Body className="d-flex justify-content-between align-items-center">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div className="d-flex align-items-center fw-bold" style={{ flex: 1 }}>
              <ContentEditable
                html={familyName}
                disabled={!isEditing}
                onChange={handleChange}
                tagName="span"
                className={isEditing ? "editable" : ""}
                onClick={handleEditToggle}
              />
              {isEditing && (
                <div className="ms-2">
                  <Button variant="success" size="sm" onClick={handleSave} className="me-2">
                    Save
                  </Button>
                  <Button variant="secondary" size="sm" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          )}
          <Button 
            variant="outline-secondary" 
            size="sm" 
            onClick={onReturn}
          >
            Return
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default FamilyInfo;
