import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, collection, getDocs, doc, getDoc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { app, db} from '../../firebase/firebaseConfig';
import ContentEditable from "react-contenteditable";
import Button from 'react-bootstrap/Button';

function IndividualDashboard({ onManageFamily }) {
  const [userEmail, setUserEmail] = useState(null);
  const [primaryFamilyID, setPrimaryFamilyID] = useState(null);
  const [associatedFamilies, setAssociatedFamilies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [familyInvites, setFamilyInvites] = useState([]);

  const loadAssociatedFamilies = useCallback(async (userEmail) => {
    try {
      const familiesSnapshot = await getDocs(collection(db, 'individualInfo', userEmail, 'families'));
      const familyIDs = familiesSnapshot.docs.map(doc => doc.id);
      setAssociatedFamilies(familyIDs);
    } catch (err) {
      console.error('Error fetching associated families:', err);
      setError('Failed to fetch family information');
    }
  }, []);

  useEffect(() => {
    const auth = getAuth(app);
    const db = getFirestore(app);

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUserEmail(user.email);
        
        try {
          // First, get the user's primary familyID
          const userDoc = await getDoc(doc(db, 'individualInfo', user.email));
          if (userDoc.exists()) {
            setPrimaryFamilyID(userDoc.data().familyID);
          }

          // Load associated families
          await loadAssociatedFamilies(user.email);
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Failed to fetch family information');
        }
      } else {
        setUserEmail(null);
        setPrimaryFamilyID(null);
        setAssociatedFamilies([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [loadAssociatedFamilies]);

  const loadFamilyInvites = useCallback(async () => {
    const invitesList = [];
    const invitesCollectionRef = collection(db, "familyInviteMessages", userEmail, "familyIDs");
    try {
      const snapshot = await getDocs(invitesCollectionRef);
      snapshot.docs.forEach(docSnap => {
        invitesList.push({ familyID: docSnap.id });
      });
      setFamilyInvites(invitesList);
    } catch (error) {
      console.error("Error loading family invites:", error);
    }
  }, [userEmail]);

  useEffect(() => {
    if (userEmail) {
      loadFamilyInvites();
    }
  }, [userEmail, loadFamilyInvites]);

  const handleSignOut = async () => {
    const auth = getAuth(app);
    try {
      await signOut(auth).then(() => {
        window.location.reload();
      });
    } catch (err) {
      console.error('Error signing out:', err);
      setError('Failed to sign out');
    }
  };

  const handleMakePrimary = async (newPrimaryFamilyID) => {
    const db = getFirestore(app);
    try {
      await updateDoc(doc(db, 'individualInfo', userEmail), {
        familyID: newPrimaryFamilyID
      });
      setPrimaryFamilyID(newPrimaryFamilyID);
    } catch (err) {
      console.error('Error updating primary family:', err);
      setError('Failed to update primary family');
    }
  };

  const acceptFamilyInvite = async (familyID) => {
    const db = getFirestore(app);
    try {
      // Remove invite
      const inviteMessageRef = doc(db, "familyInviteMessages", userEmail, "familyIDs", familyID);
      await deleteDoc(inviteMessageRef);

      // Check if there are any remaining invites
      const remainingInvites = await getDocs(collection(db, "familyInviteMessages", userEmail, "familyIDs"));
      if (remainingInvites.empty) {
        // Delete the parent document if no more invites exist
        await deleteDoc(doc(db, "familyInviteMessages", userEmail));
      }

      // Remove from pending invites
      const pendingInvitesRef = doc(db, "familyInfo", familyID, "pendingControllerInvites", userEmail);
      await deleteDoc(pendingInvitesRef);

      // Add to user's families
      const userFamilyRef = doc(db, "individualInfo", userEmail, "families", familyID);
      await setDoc(userFamilyRef, {});

      // Reload both invites and associated families
      await Promise.all([
        loadFamilyInvites(),
        loadAssociatedFamilies(userEmail)
      ]);
    } catch (error) {
      console.error("Error accepting family invite:", error);
    }
  };

  const declineFamilyInvite = async (familyID) => {
    const db = getFirestore(app);
    try {
      // Remove invite
      const inviteMessageRef = doc(db, "familyInviteMessages", userEmail, "familyIDs", familyID);
      await deleteDoc(inviteMessageRef);

      // Check if there are any remaining invites
      const remainingInvites = await getDocs(collection(db, "familyInviteMessages", userEmail, "familyIDs"));
      if (remainingInvites.empty) {
        // Delete the parent document if no more invites exist
        await deleteDoc(doc(db, "familyInviteMessages", userEmail));
      }

      // Remove from pending invites
      const pendingInvitesRef = doc(db, "familyInfo", familyID, "pendingControllerInvites", userEmail);
      await deleteDoc(pendingInvitesRef);

      // Remove from controllers
      const controllersRef = doc(db, "familyInfo", familyID, "controllers", userEmail);
      await deleteDoc(controllersRef);

      // Reload invites
      loadFamilyInvites();
    } catch (error) {
      console.error("Error declining family invite:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Welcome!</h2>
        <button className="btn btn-danger" onClick={handleSignOut}>
          Sign Out
        </button>
      </div>
      <p className="mb-4">Signed in as: {userEmail}</p>
      <h2>Families</h2>
      <div className="card">
        <div className="card-body">
          <style>
            {`
              .table tr:last-child td {
                border-bottom: none;
              }
            `}
          </style>
          <table className="table mb-0">
            <tbody>
              {primaryFamilyID && (
                <FamilyRow 
                  familyID={primaryFamilyID}
                  isPrimary={true}
                  onManage={onManageFamily}
                  onMakePrimary={handleMakePrimary}
                />
              )}
              {associatedFamilies
                .filter(familyID => familyID !== primaryFamilyID)
                .map(familyID => (
                  <FamilyRow
                    key={familyID}
                    familyID={familyID}
                    isPrimary={false}
                    onManage={onManageFamily}
                    onMakePrimary={handleMakePrimary}
                  />
                ))
              }
              {familyInvites.map(invite => (
                <InviteRow
                  key={invite.familyID}
                  familyID={invite.familyID}
                  onAccept={acceptFamilyInvite}
                  onDecline={declineFamilyInvite}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

// New component for family rows
function FamilyRow({ familyID, isPrimary, onManage, onMakePrimary }) {
  const [familyName, setFamilyName] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchFamilyName = async () => {
      const docSnap = await getDoc(doc(getFirestore(app), "familyInfo", familyID));
      if (docSnap.exists()) {
        setFamilyName(docSnap.data().name);
      }
    };
    fetchFamilyName();
  }, [familyID]);

  const handleEditToggle = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await updateDoc(doc(getFirestore(app), "familyInfo", familyID), {
        name: familyName
      });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating family name:", error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleChange = (evt) => {
    setFamilyName(evt.target.value);
  };

  return (
    <tr>
      <td>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center fw-bold" style={{ flex: 1 }}>
            <ContentEditable
              html={familyName}
              disabled={!isEditing}
              onChange={handleChange}
              tagName="span"
              className={isEditing ? "editable" : ""}
              onClick={handleEditToggle}
            />
            {isEditing && (
              <div className="ms-2">
                <Button variant="success" size="sm" onClick={handleSave} className="me-2">
                  Save
                </Button>
                <Button variant="secondary" size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <div>
            <button
              className={`btn btn-${isPrimary ? 'secondary' : 'outline-secondary'} btn-sm me-2`}
              disabled={isPrimary}
              onClick={() => !isPrimary && onMakePrimary(familyID)}
            >
              {isPrimary ? 'Primary' : 'Make Primary'}
            </button>
            <button
              className="btn btn-outline-secondary btn-sm me-2"
              onClick={() => {}}
            >
              Setup Device
            </button>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => onManage(familyID)}
            >
              Configure
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
}

// New component for invite rows
function InviteRow({ familyID, onAccept, onDecline }) {
  const [familyName, setFamilyName] = useState("");

  useEffect(() => {
    const fetchFamilyName = async () => {
      try {
        const docSnap = await getDoc(doc(getFirestore(app), "familyInfo", familyID));
        if (docSnap.exists()) {
          setFamilyName(docSnap.data().name);
        }
      } catch (error) {
        console.error("Error fetching family name:", error);
      }
    };
    fetchFamilyName();
  }, [familyID]);

  return (
    <tr>
      <td>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center fw-bold" style={{ flex: 1 }}>
            <span>{familyName || familyID}</span>
            <span className="ms-2 text-muted">(Invite)</span>
          </div>
          <div>
            <Button
              variant="outline-success"
              size="sm"
              className="me-2"
              onClick={() => onAccept(familyID)}
            >
              Accept
            </Button>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => onDecline(familyID)}
            >
              Decline
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default IndividualDashboard; 